import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Layout from '../Components/Layout';
import './main.css';
import './Responsive.css';
import Sidebar from '../Components/Sidebar';

const CGPAtoGPA = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  const [cgpa, setCgpa] = useState('');
  const [gpa, setGpa] = useState(null);
  const [history, setHistory] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const savedHistory = localStorage.getItem('cgpaGpaHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cgpaGpaHistory', JSON.stringify(history));
  }, [history]);

  // Validate input to ensure it's within the proper CGPA range (0 to 10)
  const validateInput = () => {
    if (cgpa === '' || isNaN(cgpa)) {
      setError('Please enter a valid CGPA.');
      return false;
    }
    if (cgpa < 0 || cgpa > 10) {
      setError('CGPA must be between 0 and 10.');
      return false;
    }
    setError('');
    return true;
  };

  // Corrected conversion formula: GPA = CGPA / 2
  const handleConvert = () => {
    if (!validateInput()) return;

    const numericCgpa = parseFloat(cgpa);
    const result = (numericCgpa / 2).toFixed(2); // Using CGPA / 2 for conversion

    setGpa(result);
    const newEntry = { cgpa: numericCgpa, gpa: result, date: new Date().toLocaleString() };
    setHistory([...history, newEntry]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    setGpa(null)
    localStorage.removeItem('cgpaGpaHistory');
  };

  const historyGraphData = {
    labels: history.map((entry) => entry.date),
    datasets: [
      {
        label: 'GPA over Time',
        data: history.map((entry) => entry.gpa),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
    ],
  };

  return (
    <Layout 

      pageTitle="CGPA to GPA Calculator - SGPA2Percentage"
      metaDescription="Convert your CGPA to GPA using our simple and accurate CGPA to GPA calculator."
      metaKeywords="CGPA to GPA calculator, GPA conversion, academic tools, CGPA to GPA conversion"
      canonicalUrl="https://sgpa2percentage.com/cgpa-to-gpa-calculator"
      robotsContent="index, follow"
      ogTitle="CGPA to GPA Calculator - SGPA2Percentage"
      ogDescription="Easily convert your CGPA to GPA with our efficient CGPA to GPA calculator. Convert your academic CGPA into GPA quickly."
      ogUrl="https://sgpa2percentage.com/cgpa-to-gpa-calculator"
      ogImage="https://sgpa2percentage.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://sgpa2percentage.com/cgpa-to-gpa-calculator",
        "name": "CGPA to GPA Calculator",
        "description": "Accurately convert your CGPA into GPA using our academic calculator for students.",
        "publisher": {
          "@type": "Organization",
          "name": "SGPA2Percentage",
          "logo": {
            "@type": "ImageObject",
            "url": "https://sgpa2percentage.com/img/logo.png"
          }
        }
      }}
    
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container'>
              <div className='bred'>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">CGPA To GPA Calculator</li>
                  </ol>
                </nav>
              </div>
              <h1 className='text-center'>CGPA To GPA Calculator</h1>
              <p className='text-center'>Easily convert your CGPA to GPA with our simple tool!</p>

              <div className='container text-center'>
                <div className='row'>
                  <div className='col-md-12'>
                    <label htmlFor='CGPA' className='form-label label'>
                      <b>Enter CGPA: </b><span className='text-danger'>*</span>
                    </label>
                    <input
                      type="number"
                      className='form-control'
                      id='annualInterestRate'
                      step="0.01"
                      value={cgpa}
                      onChange={(e) => setCgpa(e.target.value)}
                      placeholder="Enter your CGPA (0-10)"
                    />
                    {error && <p className="text-danger">{error}</p>}
                  </div>
                </div>
              </div>

              <div className='row input-row'>
                <div className='col-md-12'>
                  <button
                    id='investmentPeriod'
                    className='btn btn-primary'
                    onClick={handleConvert}
                    disabled={cgpa === ''}>
                    Convert to GPA
                  </button>
                </div>
              </div>
              {gpa && 
                <div className='row input-row' style={{backgroundColor:'white', height:'60px'}}><h3 className='text-center' style={{marginTop:'20px', color:'green'}}>Calculated GPA: {gpa}</h3></div>}
            </div>
            {gpa && (
              <div className='container'>
                
                <div className='row'>
                  <div className='col-md-6'>
                    {history.length > 0 && (
                      <div>
                        <h3>CGPA to GPA History Graph</h3>
                        <Doughnut data={historyGraphData} />
                      </div>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <h3>Saved History</h3>
                    <div className='table-responsive'>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>CGPA</th>
                            <th>GPA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {history.map((entry, index) => (
                            <tr key={index}>
                              <td>{entry.date}</td>
                              <td>{entry.cgpa}</td>
                              <td>{entry.gpa}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button onClick={handleClearHistory} className='btn btn-danger mt-3'>
                        Clear History
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='container'>
              <div className='row' style={{marginTop:'50px'}}>
               <hr></hr>
               <h2 className='text-center text-black'>About CGPA to GPA Calculator</h2>
               <hr></hr>
               <p style={{fontFamily:'sans-serif'}}>
               CGPA (Cumulative Grade Point Average) and GPA (Grade Point Average) are two key metrics used by educational institutions to evaluate a student’s performance. While CGPA is commonly used in countries like India on a 10-point scale, GPA is more prevalent in countries like the US, typically on a 4-point scale. For students aiming to apply to international universities or those needing to convert between these systems, a CGPA to GPA converter can be extremely helpful.
               </p>
               <h3>What are CGPA ?</h3>
               <p style={{fontFamily:'sans-serif'}}>CGPA is used to assess a student’s overall academic performance across all subjects on a 10-point scale. It is the average of the grade points obtained by the student in all subjects.</p>
               <h3>What are  GPA?</h3>
               <p style={{fontFamily:"sans-serif"}}>GPA, calculated on a 4-point scale, is a similar measure that reflects the student’s performance but usually applies to a specific semester or academic period.</p>
               <h4>Why Convert CGPA to GPA?</h4>
               <p style={{fontFamily:'sans-serif'}}>Many international universities require applicants to submit their academic performance in GPA, even if the student’s institution uses CGPA. For instance, if a student from India (where CGPA is common) applies to a US university, they will likely need to convert their CGPA to GPA.</p>
               <p style={{fontFamily:'sans-serif'}}>Without a direct method to convert between these systems, students might find themselves in need of a reliable tool to perform the conversion.</p>
               <h4>The Conversion Table</h4>
               <p style={{fontFamily:'sans-serif'}}>A common way to map GPA and CGPA values is to use a conversion table. Below is an example of how grades on the 4-point GPA scale correspond to grades on the 10-point CGPA scale.</p>

               <div className='table-responsive'>
               <table className='table' border="1" cellpadding="10" cellspacing="0">
  <thead>
    <tr>
      <th>GPA (4-Point Scale)</th>
      <th>CGPA (10-Point Scale)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>3.7 – 4.0</td>
      <td>8.5 – 10.0</td>
    </tr>
    <tr>
      <td>3.4 – 3.7</td>
      <td>8.0 – 8.4</td>
    </tr>
    <tr>
      <td>3.1 – 3.3</td>
      <td>7.5 – 7.9</td>
    </tr>
    <tr>
      <td>2.8 – 3.0</td>
      <td>7.0 – 7.4</td>
    </tr>
    <tr>
      <td>2.4 – 2.7</td>
      <td>6.5 – 6.9</td>
    </tr>
    <tr>
      <td>2.1 – 2.3</td>
      <td>6.0 – 6.4</td>
    </tr>
    <tr>
      <td>1.8 – 2.0</td>
      <td>5.1 – 5.9</td>
    </tr>
    <tr>
      <td>1.4 – 1.7</td>
      <td>5.0 – 5.4</td>
    </tr>
    <tr>
      <td>0.0 – 1.3</td>
      <td>0.0 – 4.9</td>
    </tr>
  </tbody>
</table>

               </div>
                <p style={{fontFamily:'sans-serif'}}>This table provides a rough guideline for converting between the two scales. However, it’s important to remember that the interpretation of these values might vary depending on the specific institution or region.</p>
                <h5>Example</h5>
                <p style={{fontFamily:'sans-serif'}}>Let’s walk through an example:</p>
                <div style={{fontFamily:'sans-serif'}}>
                  <ul>
                        <li>GPA: 3.2 </li>
                    <li>Formula: CGPA = (GPA / 4) * 10</li>
                    <li>Calculation: CGPA = (3.2 / 4) * 10 = 8.0</li>
                  </ul>
                  <p style={{fontFamily:'sans-serif'}}>Thus, a GPA of 3.2 corresponds to a CGPA of 8.0. Similarly, if we have a CGPA of 7.8, the GPA would be calculated as follows:</p>
                  <ul>
                    <li>CGPA: 7.8</li>
              <li>Formula: GPA = (CGPA / 10) * 4</li>
              <li>Calculation: GPA = (7.8 / 10) * 4 = 3.12</li>
                  </ul>
                  <p style={{fontFamily:"sans-serif"}}>In this case, a CGPA of 7.8 corresponds to a GPA of 3.12.</p>
                </div>
               <h5>Building a CGPA to GPA Converter</h5>
               <p style={{fontFamily:'sans-serif'}}>To make the conversion process easier, we can develop a simple CGPA to GPA converter tool using JavaScript or a web development framework like React.js. Such a tool will allow users to input their GPA or CGPA and automatically receive the corresponding value.</p>
               <h5>Importance of Using a Conversion Tool</h5>
               <p style={{fontFamily:'sans-serif'}}>Using a CGPA to GPA converter simplifies the entire process for students, saving time and ensuring accuracy. It is especially useful for students applying to international programs, where a clear understanding of both CGPA and GPA is crucial. Moreover, a well-built converter tool can reduce the likelihood of errors that might arise from manual calculations.</p>
               <h6 style={{fontFamily:'sans-serif'}}>Conclusion</h6>
               <p style={{fontFamily:'sans-serif'}}>The CGPA to GPA conversion process can seem complex at first, but by using simple formulas or a predefined conversion table, it becomes much easier to understand. Whether you’re a student applying to universities abroad or just looking to compare different grading systems, a conversion tool is a practical solution for bridging the gap between CGPA and GPA.</p>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <Sidebar/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CGPAtoGPA;
