import React ,{useEffect} from 'react'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom'

const Tools = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout 
    pageTitle="All Educational Tools - SGPA2Percentage"
metaDescription="Explore our comprehensive collection of educational tools, including GPA converters, percentage calculators, and more."
metaKeywords="Educational tools, GPA to Percentage calculator, academic tools"
canonicalUrl="https://sgpa2percentage.com/all-tools"
robotsContent="index, follow"
ogTitle="All Educational Tools - SGPA2Percentage"
ogDescription="Find all our educational calculators including GPA and percentage converters."
ogUrl="https://sgpa2percentage.com/all-tools"
ogImage="https://sgpa2percentage.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://sgpa2percentage.com/all-tools",
  "name": "All Educational Tools",
  "description": "Explore all the academic calculators and tools available on SGPA2Percentage.",
  "publisher": {
    "@type": "Organization",
    "name": "SGPA2Percentage",
    "logo": {
      "@type": "ImageObject",
      "url": "https://sgpa2percentage.com/img/logo.png"
    },
    "author": {
   "@type": "Creator",
   "name": "Ramjee Chaurasiya",
   "dateModified": "2024-09-17"
}
  }
}}

    >
      
      {/* tools section  */}

      <div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text">All tools </h1>
		 <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">All tools</li>
      </ol>
    </nav>
       </div>
    </div>
</div>
</div>

 <div className='container-fluid' style={{marginTop:'40px'}}>
<p className='text-center' style={{fontSize:'30px',marginBottom:'30px'}}>Explore Our Calculator</p>
 <div className="container">
 
  <div className="row">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>CGPA to GPA Calculator</b></h5>
    <p className="card-text">Convert your CGPA to GPA quickly and accurately with our easy-to-use calculator. </p>
    <Link to='/cgpa-to-gpa-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>CGPA to Percentage Calculator</b></h5>
    <p class="card-text">Convert your CGPA to Percentage easily and accurately with our efficient online calculator. </p>
    <Link to='/cgpa-to-percentage-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>GPA to 4.0 Scale Converter</b></h5>
    <p class="card-text">Convert your GPA to a 4.0 scale quickly and accurately with our tool.</p>
    <Link to='/gpa-to-4-scale-converter'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>

  <div class="card-body">
    <h5 class="card-title"><b>GPA to Percentage Calculator</b></h5>
    <p class="card-text">Convert your GPA to percentage format easily with our accurate GPA to Percentage Calculator.</p>
    <Link to='/gpa-to-percentage-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>

  </div>
</div>
    </div>
  </div>

  {/* second rows  */}

  <div className="row mt-5">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Marks to CGPA Calculator
</b></h5>
    <p className="card-text">Effortlessly convert your marks into CGPA using our reliable Marks to CGPA Calculator.</p>
    <Link to='/marks-to-cgpa-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>

  <div className="card-body">
    <h5 className="card-title"><b>Marks to Percentage Calculator
</b></h5>
    <p className="card-text">Accurately convert your marks to percentage with our simple Marks to Percentage Calculator.</p>
    <Link to='/marks-to-percentage-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Percentage to CGPA Calculator</b></h5>
    <p className="card-text">Convert your percentage to CGPA easily with our efficient Percentage to CGPA Calculator.</p>
    <Link to='/percentage-to-cgpa-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>

  <div className="card-body">
    <h5 className="card-title"><b>Percentage to GPA Calculator</b></h5>
    <p className="card-text">Effortlessly convert your percentage to GPA with our simple and accurate calculator tool.</p>
    <Link to='/percentage-to-gpa-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
 </div>
  </div>

    {/* third rows  */}

    <div className="row mt-5">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>SGPA to CGPA Calculator
</b></h5>
    <p className="card-text">"Accurately convert your SGPA to CGPA with ease using our reliable calculator tool."</p>
    <Link to='/sgpa-to-cgpa-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>

  <div className="card-body">
    <h5 className="card-title"><b>SGPA to Percentage
</b></h5>
    <p className="card-text">Convert your SGPA to Percentage quickly and accurately with our easy-to-use tool.</p>
    <Link to='/https://sgpa2percentage.com/'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
   
    
  </div>
</div>
</div>
 

    </Layout>
  )
}

export default Tools
