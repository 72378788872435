import React ,{useEffect} from 'react'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom'
import './About.css'
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Layout

    pageTitle="About Us - SGPA2Percentage"
metaDescription="Learn more about SGPA2Percentage and how we help students with academic calculators and tools."
metaKeywords="About SGPA2Percentage, educational tools, academic calculators"
canonicalUrl="https://sgpa2percentage.com/about-us"
robotsContent="index, follow"
ogTitle="About Us - SGPA2Percentage"
ogDescription="Discover who we are and how we provide educational calculators for students."
ogUrl="https://sgpa2percentage.com/about-us"
ogImage="https://sgpa2percentage.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://sgpa2percentage.com/about-us",
  "name": "About Us",
  "description": "Learn more about SGPA2Percentage and our educational tools for academic success.",
  "publisher": {
    "@type": "Organization",
    "name": "SGPA2Percentage",
    "logo": {
      "@type": "ImageObject",
      "url": "https://sgpa2percentage.com/img/logo.png"
    },
    "author": {
   "@type": "Creator",
   "name": "Ramjee Chaurasiya",
   "dateModified": "2024-09-17"
}
  }
}} >
     <div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text">About Us</h1>
		 <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">About us</li>
      </ol>
    </nav>
       </div>
    </div>
</div>
</div>

<div className="aboutus-secktion paddingTB60">
    <div className="container">
        <div className="row">
                	<div className="col-md-6">
                    	<img src="./img/about.jpg" id='about-img'  height={'400px'} alt="Author-images" />
                    </div>
                    <div className="col-md-6">
                    <h2 style={{marginTop:"10px"}}>Welcome to SGPA2Percentage</h2>
                    	<p> Welcome to <strong>SGPA2Percentage</strong>! We are dedicated to providing students with accurate and easy-to-use academic tools, helping them convert their SGPA, CGPA, GPA, and percentages seamlessly.</p>
                       <h3>Our Mission</h3>
                       <p> At <strong>SGPA2Percentage</strong>, our mission is to empower students with the tools they need to accurately convert their academic scores, ensuring they have the correct data for applications, evaluations, and further studies.</p>
                       <h3>Why Choose Us</h3>
                       <ul>
          <li>Accurate conversions for SGPA, CGPA, GPA, and percentages.</li>
          <li>Easy-to-use calculators designed with students in mind.</li>
          <li>Comprehensive academic tools for various educational systems.</li>
        </ul>
                    </div>
</div>
    </div>
</div>
<hr/>


<div className="container">
    <div className="inline-content">
      <div>
        <h4>Get in touch with us!</h4>
        <p>Have questions or need assistance? We're here to help.</p>
      </div>
          <button className="contact-btn">
      <Link className='text-dec' to='/contact-us' style={{ textDecoration: 'none', color: 'white' }}>Contact Us</Link>
    </button>
    </div>
  </div>
  
<div className="container">
    <h2 className="text-center mb-5 display-3">Our Team</h2>
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card h-100 text-center">
          <img src="/img/author.jpeg" height={'300px'} className="card-img-top" alt="Team Member 1" />
          <div className="card-body">
            <h5 className="card-title">Ramjee Chaurasiya</h5>
            <p className="card-text">founder & Developer</p>
            <Link to='https://www.linkedin.com/in/ramjee-chaurasiya-473b8124a/' target='_blank' ><span>Linkedin</span></Link>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card h-100 text-center">
          <img src="/img/himanshu.jpeg" height={'300px'} className="card-img-top" alt="Team Member 2" />
          <div className="card-body">
            <h5 className="card-title">Himanshu Chaurasiya</h5>
            <p>Data Scientist</p>
            
            <Link to='https://www.linkedin.com/in/himanshu-chaurasiya-b87432288/' target='_blank'><span>Linkedin</span></Link>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card h-100 text-center">
          <img src="/img/shivam.jpeg" height={'300px'} className="card-img-top" alt="Team Member 3" />
          <div className="card-body">
            <h5 className="card-title">Shivam Chaurasiya</h5>
            <p>Python Developer</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
      
    </Layout>
  )
}

export default About
