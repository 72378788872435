import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Sidebar from '../Components/Sidebar';
import { Link } from 'react-router-dom';
import Layout from '../Components/Layout';

const PercentageToCGPA = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  const [percentage, setPercentage] = useState('');
  const [scale, setScale] = useState('10.0'); // Default grading scale is 10.0
  const [cgpa, setCgpa] = useState(null);
  const [history, setHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Load history from localStorage on component mount
  useEffect(() => {
    const savedHistory = localStorage.getItem('cgpaHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Save history to localStorage whenever history state updates
  useEffect(() => {
    localStorage.setItem('cgpaHistory', JSON.stringify(history));
  }, [history]);

  // Validation for percentage
  const validatePercentage = (value) => {
    if (value < 0 || value > 100) {
      setErrorMessage('Percentage must be between 0 and 100');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleConvert = () => {
    if (!validatePercentage(percentage)) {
      return;
    }

    let result = 0;
    if (scale === '10.0') {
      result = percentage / 9.5;
    } else if (scale === '4.0') {
      result = (percentage / 100) * 4;
    } else if (scale === '5.0') {
      result = (percentage / 100) * 5;
    }

    const calculatedCgpa = result.toFixed(2); // Round off to 2 decimal places
    setCgpa(calculatedCgpa);

    const currentDate = new Date().toLocaleDateString(); // Get the current date
    // Add the new result to history
    setHistory((prevHistory) => [
      ...prevHistory,
      { date: currentDate, percentage, cgpa: calculatedCgpa, scale }
    ]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    setCgpa(null);
    localStorage.removeItem('cgpaHistory');
  };

  // Function to convert history to CSV format
  const exportToCSV = () => {
    if (history.length === 0) return;

    const csvRows = [];
    const headers = ['Date', 'Percentage', 'CGPA', 'Scale'];
    csvRows.push(headers.join(','));

    history.forEach((entry) => {
      const row = [entry.date, entry.percentage, entry.cgpa, entry.scale];
      csvRows.push(row.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'cgpa_history.csv');
    a.click();
  };

  return (
<Layout  
pageTitle="Percentage to CGPA Calculator - SGPA2Percentage"
metaDescription="Convert Percentage to CGPA easily with our academic calculator designed for student success."
metaKeywords="Percentage to CGPA, academic tools, CGPA conversion"
canonicalUrl="https://sgpa2percentage.com/percentage-to-cgpa-calculator"
robotsContent="index, follow"
ogTitle="Percentage to CGPA calculator Tool - SGPA2Percentage"
ogDescription="Use our academic tool to convert Percentage to CGPA accurately."
ogUrl="https://sgpa2percentage.com/percentage-to-cgpa-converter"
ogImage="https://sgpa2percentage.com/img/percentage_to_cgpa.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://sgpa2percentage.com/percentage-to-cgpa-calculator",
  "name": "Percentage to CGPA calculator",
  "description": "Convert your Percentage to CGPA with our educational converter tool for accurate calculations.",
  "publisher": {
    "@type": "Organization",
    "name": "SGPA2Percentage",
    "logo": {
      "@type": "ImageObject",
      "url": "https://sgpa2percentage.com/img/logo.png"
    }
  }
}}

>
<div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-8 main-section'>
          <div className='container text-center'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/all-tools' style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Percentage to CGPA Calculator
                </li>
              </ol>
            </nav>
            <h1 className='text-center'>Percentage To CGPA Calculator</h1>
            <p className='text-center'>Transform Your Percentage into CGPA with Ease!</p>
            <div className='row'>
              <div className='col-md-12'>
                <label htmlFor='Percentage' className='form-label label'>
                  <b>Enter Percentage:</b>
                  <span className='text-danger'>*</span>{' '}
                </label>
                <input
                  type='number'
                  className='form-control'
                  id='investmentPeriod'
                  value={percentage}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPercentage(value);
                    validatePercentage(value);
                  }}
                  placeholder='Enter your percentage'
                />
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
                <label>Select Grading Scale: </label>
                <select
                  value={scale}
                  onChange={(e) => setScale(e.target.value)}
                  className='form-control'
                  id='annualInterestRate'
                >
                  <option value='10.0'>10.0 Grading Scale</option>
                  <option value='4.0'>4.0 Grading Scale</option>
                  <option value='5.0'>5.0 Grading Scale</option>
                </select>
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
                <button
                  onClick={handleConvert}
                  disabled={!percentage || errorMessage}
                  className='btn btn-primary'
                >
                  Convert to CGPA
                </button>
              </div>
            </div>

            {cgpa && (
              <div className='row input-row' style={{ backgroundColor: 'white', height: '60px' }}>
                <h3 className='text-center' style={{ marginTop: '20px', color: 'green' }}>
                  Calculated CGPA: {cgpa}
                </h3>
              </div>
            )}

            {history.length > 0 && (
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <Doughnut
                      data={{
                        labels: ['CGPA', 'Remaining'],
                        datasets: [
                          {
                            data: [cgpa, scale - cgpa],
                            backgroundColor: ['#36A2EB', '#FFCE56'],
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className='col-md-6'>
                    <h3>Saved History</h3>
                    <div className='table-responsive'>
                      <table className='table table-bordered'>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Percentage</th>
                            <th>CGPA</th>
                            <th>Scale</th>
                          </tr>
                        </thead>
                        <tbody>
                          {history.map((entry, index) => (
                            <tr key={index}>
                              <td>{entry.date}</td>
                              <td>{entry.percentage}</td>
                              <td>{entry.cgpa}</td>
                              <td>{entry.scale}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button onClick={handleClearHistory} className='btn btn-danger'>
                        Clear History
                      </button>
                      <button
                        onClick={exportToCSV}
                        className='btn btn-success'
                        style={{ marginLeft: '10px' }}
                      >
                        Export to CSV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            
          </div>
          <div className='container'>
              <div className='row' style={{marginTop:"50px"}}>
                 <hr></hr>
                 <h2 className='text-center text-black'>About Percentage to CGPA Calculator</h2>
                 <hr></hr>
                 <p style={{fontFamily:'sans-serif'}}>The Percentage to CGPA Calculator is a specialized tool designed to convert percentage scores into Cumulative Grade Point Average (CGPA). This calculator is essential for students and educational institutions that need to translate percentage marks into a standardized CGPA format, which is widely used for academic grading and evaluation.</p>
                 <h3>What is CGPA?</h3>
                 <p style={{fontFamily:'sans-serif'}}>Cumulative Grade Point Average (CGPA) is a grading system that represents a student's academic performance on a scale, typically ranging from 0 to 10. It aggregates the grade points earned over a period, such as a semester or academic year, providing a comprehensive view of overall academic achievement.</p>
                 <h3>Why Convert Percentage to CGPA?</h3>
                 <div style={{fontFamily:'sans-serif'}}>
                  <ul>
                    <li>Standardized Grading: CGPA offers a standardized method for assessing academic performance, which can be more intuitive for comparing grades across different institutions or systems.</li>
                    <li>Institutional Requirements: Many educational institutions use CGPA for internal evaluations and official transcripts.</li>
                    <li>Application and Admission: CGPA is often used in university applications, scholarships, and job applications to provide a standardized measure of academic performance.</li>
                    <li>Academic Planning: Understanding CGPA helps students and advisors track academic progress and make informed decisions about future studies or career paths.</li>
                  </ul>
                 </div>
                 <h4>Features of the Percentage to CGPA Calculator</h4>
                 <div style={{fontFamily:'sans-serif'}}>
                  <ul>
                    <li>Accurate Conversion: Provides precise CGPA based on the entered percentage and conversion formula or scale.</li>
                    <li>User-Friendly Interface: Easy to use, with clear input fields for percentage and automatic conversion to CGPA.</li>
                    <li>Standardized Results: Converts percentage to CGPA according to widely accepted grading scales or institutional standards.</li>
                    <li>Instant Feedback: Calculates and displays results immediately after input.</li>
                  </ul>
                 </div>
                 <h4>Benefits of Using the Percentage to CGPA Calculator</h4>
                 <div style={{fontFamily:'sans-serif'}}>
                  <ul>
                    <li>Standardized Academic Measurement: Converts percentage marks to a standardized CGPA format, which is useful for academic evaluations and comparisons.</li>
                    <li>Efficient Calculation: Provides quick and accurate conversion without the need for manual calculations or complex formulas.</li>
                    <li>Academic Planning: Helps students and educators understand and interpret academic performance in CGPA terms.</li>
                    <li>Application Readiness: Prepares accurate CGPA scores for applications, transcripts, and other official documents.</li>
                  </ul>
                 </div>
                 <h5>Who Should Use This Calculator?</h5>
                 <div style={{fontFamily:'sans-serif'}}>
                  <ul>
                    <li>Students who need to convert their percentage scores into CGPA for academic purposes or applications.</li>
                    <li>Educators who require a tool to translate student percentages into CGPA for grading or reporting.</li>
                    <li>Academic Advisors who need to assess and guide students based on CGPA.</li>
                    <li>Educational Institutions that use CGPA for internal evaluations and standardized grading.</li>
                  </ul>
                 </div>
                 <p style={{fontFamily:"sans-serif"}}>The Percentage to CGPA Calculator simplifies the process of converting percentage scores into CGPA, helping students, educators, and institutions interpret and present academic performance in a standardized format. Whether for personal understanding, official documentation, or academic planning, this tool provides a reliable solution for translating percentage marks into CGPA.</p>
              </div>
            </div>
        </div>
        <div className='col-md-3'>
          <Sidebar />
        </div>
      </div>
    </div>
</Layout>
  );
};

export default PercentageToCGPA;
