import React  ,{useEffect}from 'react'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom'

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Layout 

pageTitle="Contact Us - SGPA2Percentage"
metaDescription="Have questions? Get in touch with SGPA2Percentage for inquiries regarding our educational tools and services."
metaKeywords="Contact SGPA2Percentage, academic tools support, student inquiries"
canonicalUrl="https://sgpa2percentage.com/contact-us"
robotsContent="index, follow"
ogTitle="Contact Us - SGPA2Percentage"
ogDescription="Reach out to us for any inquiries or support regarding our educational calculators."
ogUrl="https://sgpa2percentage.com/contact-us"
ogImage="https://sgpa2percentage.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://sgpa2percentage.com/contact-us",
  "name": "Contact Us",
  "description": "Contact SGPA2Percentage for support or inquiries regarding our academic tools and services.",
  "publisher": {
    "@type": "Organization",
    "name": "SGPA2Percentage",
    "logo": {
      "@type": "ImageObject",
      "url": "https://sgpa2percentage.com/img/logo.png"
    }
  }
}}

    
    >

<div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text">Contact Us</h1>
		  <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">Contact Us</li>
      </ol>
    </nav>
     </div>
    </div>
</div>
</div>

<section class="bg-light py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <h2 class="mb-4 display-5 text-center">Contact Us</h2>
          <p class="text-center mb-4" id='about-para'>Please fill out the form below to connect with us.</p>
          <p class="text-center mb-4" id='about-para'>
            Email: <Link to="mailto:business.collagepoint@gmail.com">business.collagepoint@gmail.com</Link> <br/>
            WhatsApp: <span> +91 8871740585</span><br/>
            <Link to='https://forms.gle/YERy1zwkozxN419F6' target='_blank'>Google Form</Link>
          </p>
        </div>
      </div>
    </div>
  </section>

      
    </Layout>
  )
}

export default Contact
