import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale } from 'chart.js';
import Layout from '../Components/Layout';
import { Link } from 'react-router-dom';
import './main.css'
import './Responsive.css'
import Sidebar from '../Components/Sidebar';

// Register necessary components for Chart.js
ChartJS.register(LineElement, CategoryScale, LinearScale);

const MarksToCgpa = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const [marks, setMarks] = useState([{ subject: '', mark: '' }]);
  const [scale, setScale] = useState('10.0'); // Default grading scale
  const [cgpa, setCgpa] = useState(null);
  const [history, setHistory] = useState([]);

  // Load history from localStorage on component mount
  useEffect(() => {
    const savedHistory = localStorage.getItem('marksCgpaHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Save history to localStorage whenever history state updates
  useEffect(() => {
    localStorage.setItem('marksCgpaHistory', JSON.stringify(history));
  }, [history]);

  const handleAddSubject = () => {
    setMarks([...marks, { subject: '', mark: '' }]);
  };

  const handleMarkChange = (index, field, value) => {
    const updatedMarks = [...marks];
    updatedMarks[index][field] = value;
    setMarks(updatedMarks);
  };

  const handleCalculateCGPA = () => {
    let totalMarks = 0;
    let totalSubjects = 0;

    marks.forEach(({ mark }) => {
      const numericMark = parseFloat(mark) || 0;
      totalMarks += numericMark;
      if (numericMark > 0) totalSubjects++;
    });

    if (totalSubjects === 0) return;

    let cgpaResult;
    if (scale === '4.0') {
      cgpaResult = (totalMarks / (totalSubjects * 100) * 4).toFixed(2);
    } else if (scale === '5.0') {
      cgpaResult = (totalMarks / (totalSubjects * 100) * 5).toFixed(2);
    } else if (scale === '10.0') {
      cgpaResult = (totalMarks / (totalSubjects * 100) * 10).toFixed(2);
    }

    setCgpa(cgpaResult);

    // Save result to history
    const newEntry = {
      marks,
      cgpa: cgpaResult,
      scale,
      date: new Date().toLocaleString(),
    };
    setHistory((prevHistory) => [...prevHistory, newEntry]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    setCgpa(null)
    localStorage.removeItem('marksCgpaHistory');
  };

  // Prepare data for the Marks to CGPA history graph
  const historyGraphData = {
    labels: history.map((entry) => entry.date),
    datasets: [
      {
        label: 'CGPA over Time',
        data: history.map((entry) => entry.cgpa),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
    ],
  };

  return (
    <Layout 

     pageTitle="Marks to CGPA Calculator - SGPA2Percentage"
      metaDescription="Convert your marks to CGPA using our accurate and easy-to-use Marks to CGPA calculator."
      metaKeywords="marks to CGPA calculator, CGPA conversion, academic tools, marks to CGPA conversion"
      canonicalUrl="https://sgpa2percentage.com/marks-to-cgpa-calculator"
      robotsContent="index, follow"
      ogTitle="Marks to CGPA Calculator - SGPA2Percentage"
      ogDescription="Convert your academic marks into CGPA easily using our Marks to CGPA calculator."
      ogUrl="https://sgpa2percentage.com/marks-to-cgpa-calculator"
      ogImage="https://sgpa2percentage.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://sgpa2percentage.com/marks-to-cgpa-calculator",
        "name": "Marks to CGPA Calculator",
        "description": "Accurately convert your marks into CGPA with our easy-to-use academic calculator for students.",
        "publisher": {
          "@type": "Organization",
          "name": "SGPA2Percentage",
          "logo": {
            "@type": "ImageObject",
            "url": "https://sgpa2percentage.com/img/logo.png"
          }
        }
      }}
    
    >

    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'>
          
        </div>
        <div className='col-md-8 main-section'>
          <div className='container text-center'>
          <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Marks To CGPA Calculator</li>
                  </ol>
                </nav>
                <h1 className='text-center'>Marks To CGPA Calculator</h1>
                <p className='text-center'>Simplifying Marks to CGPA Conversion, One Click Away!</p>

                <div className='row'>
                  <div className='col-md-12'>
                  <label htmlFor='GradingScale' className='form-label label'><b>Select Grading Scale: </b><span className='text-danger'>*</span></label>
              <select  className='form-control' value={scale} onChange={(e) => setScale(e.target.value)} id='investmentPeriod'>
                <option value="10.0">10.0 Grading Scale</option>
                <option value="4.0">4.0 Grading Scale</option>
                <option value="5.0">5.0 Grading Scale</option>
              </select>
                  </div>
                </div>
                
                  {marks.map((mark, index) => (
        <div key={index}>
        <div className='row input-row'>
        <div className='col-md-12'>
          <label htmlFor='Subject' className='form-label label'><b>Subject Name:</b><span className='text-danger'>*</span></label>
          <input
            type="text"
            className='form-control'
            id='annualInterestRate'
            value={mark.subject}
            onChange={(e) => handleMarkChange(index, 'subject', e.target.value)}
            placeholder={`Subject ${index + 1}`}

            required
          />
            </div>
            </div>
         
        <div className='row input-row'>
          <div className='col-md-12'>
          <label htmlFor='Marks' className='form-label label'><b>Marks Obtained:</b> <span className='text-danger'>*</span></label>
          <input
            type="number"
            className='form-control'
            id='annualInterestRate'
            step="0.01"
            value={mark.mark}
            onChange={(e) => handleMarkChange(index, 'mark', e.target.value)}
            placeholder={`Marks ${index + 1}`}
            required
          />
          </div>
        </div>
      
        </div>

       
      ))}
      <div className='row input-row'>
           <div className='col-md-12'>
           <button onClick={handleAddSubject}className='btn btn-primary'  style={{ marginRight: '10px' }} >Add Subject</button>

          <button onClick={handleCalculateCGPA} className='btn btn-success' disabled={marks.some(mark => mark.mark === '')}>
            Calculate CGPA
          </button>
           </div>
          </div>
                
          </div>
          {cgpa && (
        <div className='row input-row' style={{backgroundColor:'white', height:"60px"}}>
          <h3 className='text-center' style={{marginTop:"20px", color:'green'}}>Calculated CGPA: {cgpa}</h3>
        </div>

      )}
      {history.length > 0 && (
        <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
          <h3>Marks to CGPA History Graph</h3>
          <Doughnut data={historyGraphData} />
          </div>
          <div className='col-md-6'>
          <h3>Saved History</h3>
             <div className='table-responsive'>
             <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Scale</th>
                    <th>Marks</th>
                    <th>CGPA</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.date}</td>
                      <td>{entry.scale}</td>
                      <td>
                        {entry.marks.map(({ subject, mark }, i) => (
                          <div key={i}>
                            Subject {i + 1}: {subject} - {mark}
                          </div>
                        ))}
                      </td>
                      <td>{entry.cgpa}</td>
                    </tr>
                  ))}
                </tbody>
            </table>
            <button onClick={handleClearHistory} className='btn btn-danger'>Clear History</button>
             </div>
          </div>
        </div>
          
      
          
        </div>
      )}
        <div className='container'>
          <div className='row' style={{marginTop:'50px'}}>
          <hr></hr>
          <h2 className='text-center text-black'>About Marks to CGPA Calculator</h2>

          <hr></hr>

          <p style={{fontFamily:'sans-serif'}}>The Marks to CGPA Calculator is a useful tool designed to convert your marks or percentage scores into CGPA (Cumulative Grade Point Average). CGPA is a grading system used by many educational institutions to represent a student’s academic performance. This calculator helps students, educators, and academic advisors easily translate numerical marks into a CGPA format, making it easier to understand and compare academic achievements.</p>

          <h3>What is CGPA?</h3>
          <p style={{fontFamily:'sans-serif'}}>CGPA stands for Cumulative Grade Point Average, which is a measure of a student’s academic performance over a specific period, typically a semester or an academic year. Unlike percentage scores that represent the total marks obtained out of a maximum possible score, CGPA provides a standardized way to evaluate performance across different courses and subjects.</p>
          <p style={{fontFamily:'sans-serif'}}>The CGPA is usually calculated on a scale, such as 4.0, 5.0, or 10.0, depending on the educational system. For instance:</p>
          <div style={{fontFamily:'sans-serif'}}>
            <li>On a 4.0 scale: CGPA is often used in universities in the U.S. and some other countries.</li>
            <li>On a 10.0 scale: Common in countries like India and some European nations.</li>
          </div>

          <h3 style={{marginTop:'10px'}}>Why Convert Marks to CGPA?</h3>
          <p style={{fontFamily:'sans-serif'}}>Converting marks to CGPA can be essential for several reasons:</p>
          <div style={{fontFamily:'sans-serif'}}>
            <ul>
              <li>Standardization: Different institutions use different grading systems. Converting your marks to CGPA helps standardize your performance evaluation for applications or transfers between institutions.</li>
              <li>Comparative Analysis: CGPA allows for a more straightforward comparison of academic performance across different educational systems or grading scales.</li>
              <li>Understanding Academic Standing: CGPA provides a clearer picture of overall academic performance, especially when evaluating progress over multiple semesters or academic years.</li>
            </ul>
          </div>

          <h4>How Does the Marks to CGPA Calculator Work?</h4>

          <p style={{fontFamily:'sans-serif'}}>The calculator uses a specific formula to convert your numerical marks or percentage scores into CGPA. Here’s a simplified process:</p>
          <div style={{fontFamily:'sans-serif'}}>
            <ul>
              <li>Enter Your Marks or Percentage: Input your numerical marks or percentage for each subject or course.</li>
              <li>Select Your Grading Scale: Choose the CGPA scale (e.g., 4.0, 5.0, 10.0) that your institution uses.</li>
              <li>Calculate: The tool will compute your CGPA based on the entered marks and selected scale.</li>
            </ul>

          </div>

          <h4>Features of the Marks to CGPA Calculator</h4>
            <div style={{fontFamily:'sans-serif'}}>
              <ul>
                <li>Accurate Conversion: Converts your numerical marks or percentage into CGPA with precision.</li>
                <li>Supports Multiple CGPA Scales: Works with various CGPA scales, allowing flexibility based on your educational system.</li>

                <li>User-Friendly: Designed to be intuitive and easy to use, even for those who may not be familiar with CGPA calculations.</li>
                <li>Instant Results: Provides quick and accurate results, helping you understand your academic performance efficiently.</li>
              </ul>
            </div>

            <h4>Benefits of Using the Marks to CGPA Calculator</h4>
            <div style={{fontFamily:'sans-serif'}}>
              <ul>
                <li>Standardized Performance Evaluation: Helps in understanding your performance in a standardized CGPA format.</li>
                <li>Ease of Comparison: Makes it easier to compare academic performance across different educational institutions or grading systems.</li>
                <li>Academic Planning: Assists in academic planning and goal setting by providing a clear view of your cumulative performance.</li>
                <li>Application Preparation: Facilitates the preparation of applications for higher education or scholarships by providing a clear CGPA representation.</li>
              </ul>
            </div>

            <h5>Who Should Use This Calculator?</h5>
            <p style={{fontFamily:'sans-serif'}}>Students who need to convert their marks or percentage scores into CGPA for academic evaluation, application purposes, or personal understanding.</p>
            <p style={{fontFamily:"sans-serif"}}>Educational Institutions looking to standardize or compare students’ performance across different grading systems.</p>
            <p style={{fontFamily:'sans-serif'}}>Academic Advisors and Counselors who need to assist students in understanding their performance and making informed academic decisions.</p>
          </div>
        </div>

        </div>
        <div className='col-md-3'>
         <Sidebar/>
        </div>
      </div>
    </div>

   
   

   

     

    
    </Layout>
  );
};

export default MarksToCgpa;
