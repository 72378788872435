import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Layout from '../Components/Layout';
import { Link } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';

const CGPAToPercentage = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [cgpa, setCgpa] = useState('');
  const [scale, setScale] = useState('10.0'); // Default grading scale is 10.0
  const [percentage, setPercentage] = useState(null);
  const [history, setHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Load history from localStorage on component mount
  useEffect(() => {
    const savedHistory = localStorage.getItem('cgpaToPercentageHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Save history to localStorage whenever history state updates
  useEffect(() => {
    localStorage.setItem('cgpaToPercentageHistory', JSON.stringify(history));
  }, [history]);

  // Validation for CGPA
  const validateCGPA = (value) => {
    if (value < 0 || value > (scale === '10.0' ? 10 : scale === '4.0' ? 4 : 5)) {
      setErrorMessage(`CGPA must be between 0 and ${scale}`);
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleConvert = () => {
    if (!validateCGPA(cgpa)) {
      return;
    }

    let result = 0;

    if (scale === '10.0') {
      result = cgpa * 9.5;
    } else if (scale === '4.0') {
      result = (cgpa / 4.0) * 100;
    } else if (scale === '5.0') {
      result = (cgpa / 5.0) * 100;
    }

    setPercentage(result.toFixed(2)); // Round off to 2 decimal places

    const newEntry = {
      cgpa,
      percentage: result.toFixed(2),
      scale,
      date: new Date().toLocaleString(),
    };
    setHistory((prevHistory) => [...prevHistory, newEntry]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    setPercentage(null)
    localStorage.removeItem('cgpaToPercentageHistory');
  };

  // Function to convert history to CSV format
  const exportToCSV = () => {
    if (history.length === 0) return;

    const csvRows = [];
    const headers = ['Date', 'CGPA', 'Percentage', 'Scale'];
    csvRows.push(headers.join(','));

    history.forEach((entry) => {
      const row = [entry.date, entry.cgpa, entry.percentage, entry.scale];
      csvRows.push(row.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'cgpa_to_percentage_history.csv');
    a.click();
  };

  return (
    <Layout 

pageTitle="CGPA to Percentage Calculator - SGPA2Percentage"
metaDescription="Easily convert your CGPA to Percentage using our accurate CGPA to Percentage calculator."
metaKeywords="CGPA to Percentage calculator, CGPA conversion, academic tools"
canonicalUrl="https://sgpa2percentage.com/cgpa-to-percentage-calculator"
robotsContent="index, follow"
ogTitle="CGPA to Percentage Calculator - SGPA2Percentage"
ogDescription="Convert your CGPA to Percentage using our reliable academic calculator."
ogUrl="https://sgpa2percentage.com/cgpa-to-percentage-calculator"
ogImage="https://sgpa2percentage.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://sgpa2percentage.com/cgpa-to-percentage-calculator",
  "name": "CGPA to Percentage Calculator",
  "description": "Use our CGPA to Percentage calculator for quick and accurate conversions.",
  "publisher": {
    "@type": "Organization",
    "name": "SGPA2Percentage",
    "logo": {
      "@type": "ImageObject",
      "url": "https://sgpa2percentage.com/img/logo.png"
    }
  }
}}

    >

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container text-center'>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">CGPA To Percentage Calculator</li>
                </ol>
              </nav>
              <h1 className='text-center'>CGPA to Percentage Calculator</h1>
              <p className='text-center'>Transform Your CGPA into Percentage with Ease!</p>
              <div className='row'>
                <div className='col-md-12'>
                  <label htmlFor='cgpa' className='form-label label'><b>Enter CGPA:</b> <span className='text-danger'>*</span></label>
                  <input
                    type="number"
                    className='form-control'
                    id='investmentPeriod'
                    value={cgpa}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCgpa(value);
                      validateCGPA(value);
                    }}
                    placeholder="Enter your CGPA"
                  />
                </div>
              </div>
              <div className='row input-row'>
                <div className='col-md-12'>
                  <label htmlFor="Grading" className="form-label label">Select Grading Scale:</label>
                  <select value={scale} className='form-control' id='annualInterestRate' onChange={(e) => setScale(e.target.value)}>
                    <option value="10.0">10.0 Grading Scale</option>
                    <option value="4.0">4.0 Grading Scale</option>
                    <option value="5.0">5.0 Grading Scale</option>
                  </select>
                </div>
              </div>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              <div className='row input-row'>
                <div className='col-md-12'>
                  <button className='btn btn-primary' onClick={handleConvert} disabled={!cgpa || errorMessage}>
                    Convert to Percentage
                  </button>
                </div>
              </div>
              {percentage && (
                <div className='row input-row' style={{ backgroundColor: 'white', height: "60px" }}>
                  <h3 className='text-center' style={{ marginTop: "20px", color: "green" }}>Calculated Percentage: {percentage}%</h3>
                </div>
              )}

              {percentage && (
                <div className='row input-row'>
                  <div className='col-md-6'>
                    <Doughnut
                      data={{
                        labels: ['Percentage', 'Remaining'],
                        datasets: [
                          {
                            data: [percentage, 100 - percentage],
                            backgroundColor: ['#36A2EB', '#FFCE56'],
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className='col-md-6'>
                    <h3 className='text-center'>Saved History</h3>
                    <div className='table-responsive'>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>CGPA</th>
                            <th>Percentage</th>
                            <th>Scale</th>
                          </tr>
                        </thead>
                        <tbody>
                          {history.map((entry, index) => (
                            <tr key={index}>
                              <td>{entry.date}</td>
                              <td>{entry.cgpa}</td>
                              <td>{entry.percentage}%</td>
                              <td>{entry.scale}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button onClick={handleClearHistory}>Clear History</button>
                      <button onClick={exportToCSV} style={{ marginLeft: '10px' }}>
                        Export to CSV
                      </button>
                    </div>
                  </div>
                </div>
              )}
           
            </div>
            <div className='container'>
                <div className='row' style={{marginTop:'50px'}}>
                  <hr></hr>
                  <h2 className='text-center text-black'>About CGPA to Percentage Calculator </h2>
                  <hr></hr>
                  <p style={{fontFamily:'sans-serif'}}>
                  In today’s competitive academic environment, understanding how to convert your CGPA (Cumulative Grade Point Average) into a percentage is crucial for a variety of purposes, including job applications, further studies, and more. To make this process easier for students, we are excited to introduce our new CGPA to Percentage Calculator tool. This innovative tool is designed to provide accurate and instant conversions of CGPA to percentage based on different grading scales.


                  </p>
                  <h3 style={{marginTop:'10px'}}>What is CGPA?</h3>
                  <p style={{fontFamily:'sans-serif'}}>CGPA stands for Cumulative Grade Point Average and is a standard grading system used by many educational institutions to evaluate student performance. It is typically calculated on a scale, such as 10.0, 4.0, or 5.0. Each grading scale represents a different maximum value, and converting CGPA to a percentage helps in comparing academic performance across different institutions and systems.</p>
                  <h4 style={{marginTop:'20px'}}><strong>Key Features of Our Tool</strong></h4>
                  <div style={{fontFamily:'sans-serif'}}>
                  
                      <p><b>User-Friendly Interface:
</b>Our tool features an intuitive and clean design, ensuring that users can easily navigate and perform conversions without any hassle. Simply enter your CGPA, select the grading scale, and click on "Convert to Percentage."</p>
<p><b>Support for Multiple Grading Scales:</b>
The calculator supports various grading scales:
10.0 Grading Scale: The most common scale used in many institutions.
4.0 Grading Scale: Often used in many American universities.
5.0 Grading Scale: Another common scale in various educational systems.
</p>
<p> <b>Instant Results:</b>
Upon entering your CGPA and selecting the appropriate grading scale, the tool instantly calculates and displays the percentage equivalent. This allows for immediate feedback and eliminates the need for manual calculations.</p>
<p><b>Historical Data Storage</b>
The tool keeps a record of your past conversions, providing easy access to your conversion history. You can view, clear, and even export this data to a CSV file for further analysis or record-keeping.


</p>

<h4 style={{marginTop:'20px'}}>How to Use the Calculator</h4>
<p><b>Step 1:</b> Enter your CGPA in the designated input field.</p>

<p><b>Step 2:</b> Select the grading scale from the drop-down menu. Choose from 10.0, 4.0, or 5.0 based on the scale used by your institution.</p>

<p><b>Step 3:</b> Click on the "Convert to Percentage" button. The tool will process your input and display the corresponding percentage.</p>

<p><b>Step 4:</b> Review the calculated percentage and view the graphical representation.</p>

<p><b>Step 5:</b> Check your historical data if needed, and use the "Clear History" or "Export to CSV" options as required.</p>
<h4>Potential Use Cases</h4>
<p>Students can use the tool to convert their CGPA to percentage scores, which is useful for applying to universities, scholarships, or job opportunities where percentage scores are preferred.</p>
<p>Institutions can integrate this tool into their academic services to assist students in understanding and converting their CGPA to percentage formats, ensuring consistency and transparency.</p>
<p>Academic advisors can utilize the tool to provide accurate guidance to students on their academic performance and assist in planning their academic paths.</p>
<p>Employers can use the tool to standardize and compare academic qualifications of job applicants from different educational systems, aiding in the recruitment process.</p>
<h5>Conclusion</h5>
<p>Our CGPA to Percentage Calculator is a powerful and easy-to-use tool designed to help students and academic professionals quickly convert CGPA to percentage. With its support for multiple grading scales, instant results, and visual representation, it simplifies the process of understanding academic performance. Whether you are a student planning your future or an academic professional needing accurate data, our tool is here to assist you.</p>


                  
                  </div>
                </div>
              </div>
          </div>
          <div className='col-md-3'>
            <Sidebar/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CGPAToPercentage;
