import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';
import Sidebar from '../Components/Sidebar';
import { Link } from 'react-router-dom';
import './main.css';
import './Responsive.css';
import Layout from '../Components/Layout';

// Register necessary components for Chart.js
ChartJS.register(LineElement, CategoryScale, LinearScale, ArcElement);

const PercentageToGPA = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  const [percentage, setPercentage] = useState('');
  const [scale, setScale] = useState('4.0');
  const [gpa, setGpa] = useState(null);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const savedHistory = localStorage.getItem('percentageGpaHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('percentageGpaHistory', JSON.stringify(history));
  }, [history]);

  const handleConvert = () => {
    let result = 0;

    if (scale === '4.0') {
      result = (percentage / 100) * 4;
    } else if (scale === '5.0') {
      result = (percentage / 100) * 5;
    } else if (scale === '10.0') {
      result = (percentage / 100) * 10;
    }

    setGpa(result.toFixed(2));

    const newEntry = {
      percentage,
      gpa: result.toFixed(2),
      scale,
      date: new Date().toLocaleString(),
    };
    setHistory((prevHistory) => [...prevHistory, newEntry]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    setGpa(null);
    localStorage.removeItem('percentageGpaHistory');
  };

 

  const doughnutData = {
    labels: ['4.0 Scale', '5.0 Scale', '10.0 Scale'],
    datasets: [
      {
        data: [
          history.filter((entry) => entry.scale === '4.0').length,
          history.filter((entry) => entry.scale === '5.0').length,
          history.filter((entry) => entry.scale === '10.0').length,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  return (
   <Layout 

      pageTitle="Percentage to GPA Calculator - SGPA2Percentage"
      metaDescription="Easily convert your percentage to GPA using our accurate Percentage to GPA Calculator."
      metaKeywords="percentage to GPA calculator, academic GPA conversion, educational tools"
      canonicalUrl="https://sgpa2percentage.com/percentage-to-gpa-calculator"
      robotsContent="index, follow"
      ogTitle="Percentage to GPA Calculator - SGPA2Percentage"
      ogDescription="Convert your percentage score to GPA with our easy-to-use academic tool."
      ogUrl="https://sgpa2percentage.com/percentage-to-gpa-calculator"
      ogImage="https://sgpa2percentage.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://sgpa2percentage.com/percentage-to-gpa-calculator",
        "name": "Percentage to GPA Calculator",
        "description": "Use our Percentage to GPA calculator to easily convert your percentage scores into GPA format.",
        "publisher": {
          "@type": "Organization",
          "name": "SGPA2Percentage",
          "logo": {
            "@type": "ImageObject",
            "url": "https://sgpa2percentage.com/img/logo.png"
          }
        }
      }}
   
   >
     <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-8 main-section'>
          <div className='container text-center'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Percentage To GPA Calculator</li>
              </ol>
            </nav>
            <h1 className='text-center'>Percentage to GPA Calculator</h1>
            <p className='text-center'>Transform Your Percentage into GPA with Ease!</p>
            <div className='row'>
              <div className='col-md-12'>
                <label htmlFor='Percentage' className='form-label label'>
                  <b>Enter Percentage:</b><span className='text-danger'>*</span>
                </label>
                <input
                  type="number"
                  className='form-control'
                  id='investmentPeriod'
                  value={percentage}
                  onChange={(e) => setPercentage(e.target.value)}
                  placeholder="Enter percentage"
                />
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
                <label htmlFor='GradingScale' className='form-label label'>
                  <b>Select Grading Scale: </b><span className='text-danger'>*</span>
                </label>
                <select value={scale} onChange={(e) => setScale(e.target.value)} className='form-control' id='annualInterestRate'>
                  <option value="4.0">4.0 Grading Scale</option>
                  <option value="5.0">5.0 Grading Scale</option>
                  <option value="10.0">10.0 Grading Scale</option>
                </select>
              </div>
            </div>

            <div className='row input-row'>
              <div className='col-md-12'>
                <button className='btn btn-primary' onClick={handleConvert} disabled={percentage === ''}>
                  Convert to GPA
                </button>
              </div>
            </div>
          </div>

          {gpa && (
            <div className="row input-row" style={{ backgroundColor: 'white', height: '60px' }}>
              <h3 className='text-center' style={{ marginTop: '20px', color: 'green' }}>Calculated GPA: {gpa}</h3>
            </div>
          )}

          {history.length > 0 && (
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='text-center'>GPA Distribution by Scale</h3>
              <Doughnut data={doughnutData} />
            </div>
            <div className='col-md-6'>
              <div className='table-responsive'>
                <h3>Saved History</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Scale</th>
                      <th>Percentage</th>
                      <th>GPA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((entry, index) => (
                      <tr key={index}>
                        <td>{entry.date}</td>
                        <td>{entry.scale}</td>
                        <td>{entry.percentage}</td>
                        <td>{entry.gpa}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button className="btn btn-danger" onClick={handleClearHistory}>Clear History</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='container'>
        <div className='row' style={{marginTop:'50px'}}></div>
        <hr></hr>
        <h2 className='text-center text-black'>About Percentage to GPA Calculator</h2>
        <hr></hr>
        <p style={{fontFamily:'sans-serif'}}>The Percentage to GPA Calculator is an essential tool designed to convert percentage marks into a Grade Point Average (GPA). This conversion is crucial for students, educational institutions, and employers who need to evaluate academic performance using a standardized grading system.</p>
        <h3>Why Convert Percentage to GPA?</h3>
        <div style={{fontFamily:'sans-serif'}}>
          <p style={{fontFamily:'sans-serif'}}>Converting percentages to GPA is useful for various reasons:</p>
          <ul>
            <li>International Applications: Many universities, especially in countries like the United States, Canada, and the UK, use GPA as a grading system. Converting percentage marks into GPA allows students to submit applications with a grading scale familiar to admissions officers.</li>
            <li>Uniform Comparison: GPA offers a standardized format for comparing academic performance, especially when students come from diverse educational backgrounds with different grading systems.</li>
            <li>Career Opportunities: Many employers ask for GPA scores in job applications, particularly in academic or specialized industries. Converting percentage marks to GPA helps students present their academic qualifications in a format that employers recognize.</li>
            <li>Ease of Interpretation: GPA allows for easy interpretation of a student's overall academic standing without the complexities of varying percentage systems.</li>
          </ul>
        </div>
        <h3>Features of the Percentage to GPA Calculator</h3>
        <div style={{fontFamily:"sans-serif"}}>
          <ul>
            <li>Multiple GPA Scales: Supports conversions to 4.0, 5.0, and 10.0 GPA scales.</li>
            <li>Instant Calculation: Provides real-time conversion, instantly transforming percentage scores into GPA.</li>
            <li>Customizable: Allows users to choose their specific GPA scale based on their institution's requirements.</li>
            <li>User-Friendly Interface: Simple input fields and clear results make the conversion process seamless for users.</li>
          </ul>
        </div>
        <h4>Benefits of Using the Percentage to GPA Calculator</h4>
        <div style={{fontFamily:'sans-serif'}}>
          <ul>
            <li>Standardization: Converts percentage marks into a standardized GPA format that is recognized globally, making academic performance more understandable.</li>
            <li>Quick and Efficient: Eliminates the need for manual calculations and provides accurate GPA scores in seconds.</li>
            <li>Adaptable to Various GPA Systems: The calculator supports multiple grading scales, allowing for flexibility based on different academic institutions.</li>
            <li>Essential for Applications: Helps students prepare GPA scores for university applications, scholarship submissions, and job applications.</li>
          </ul>
        </div>
        <p style={{fontFamily:'sans-serif'}}>The Percentage to GPA Calculator is an indispensable tool for anyone looking to convert percentage scores into a universally accepted GPA format. Whether you're applying for higher education, preparing your academic profile for job applications, or simply tracking your academic performance, this calculator simplifies the process and delivers accurate results every time.</p>
      </div>
        </div>
        <div className='col-md-3'>
          <Sidebar />
        </div>
      </div>

     

    
    </div>
   </Layout>
  );
};

export default PercentageToGPA;
