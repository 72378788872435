import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Layout from '../Components/Layout';
import { Link } from 'react-router-dom';
import './main.css';
import './Responsive.css';
import Sidebar from '../Components/Sidebar';

const GPAToPercentageConverter = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  const [gpa, setGpa] = useState('');
  const [scale, setScale] = useState('4.0'); // Default GPA scale is 4.0
  const [convertedGpa, setConvertedGpa] = useState(null);
  const [history, setHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Load history from localStorage on component mount
  useEffect(() => {
    const savedHistory = localStorage.getItem('gpaToPercentageHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Save history to localStorage whenever history state updates
  useEffect(() => {
    localStorage.setItem('gpaToPercentageHistory', JSON.stringify(history));
  }, [history]);

  // Validate the GPA input based on the selected scale
  const validateGPA = (value) => {
    const numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
      setErrorMessage('Please enter a valid number.');
      return false;
    }

    // Custom validation for different scales
    const maxGpa = parseFloat(scale);
    if (numericValue < 0 || numericValue > maxGpa) {
      setErrorMessage(`GPA must be between 0 and ${maxGpa}.`);
      return false;
    }

    setErrorMessage('');
    return true;
  };

  // Conversion logic: Converts GPA to percentage based on the selected scale
  const convertToPercentage = () => {
    if (!validateGPA(gpa)) {
      return;
    }

    const percentage = (parseFloat(gpa) / parseFloat(scale)) * 100;
    const remaining = 100 - percentage;
    setConvertedGpa({ percentage: percentage.toFixed(2), remaining: remaining.toFixed(2) });

    const newEntry = {
      gpa,
      scale,
      percentage: percentage.toFixed(2),
      remaining: remaining.toFixed(2),
      date: new Date().toLocaleString(),
    };

    setHistory((prevHistory) => [...prevHistory, newEntry]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    setConvertedGpa(null);
    localStorage.removeItem('gpaToPercentageHistory');
  };

  return (
    <Layout 

pageTitle="GPA to Percentage Calculator - SGPA2Percentage"
metaDescription="Convert GPA to Percentage effortlessly with our GPA to Percentage calculator for academic purposes."
metaKeywords="GPA to Percentage, GPA conversion, academic tools"
canonicalUrl="https://sgpa2percentage.com/gpa-to-percentage-calculator"
robotsContent="index, follow"
ogTitle="GPA to Percentage Calculator - SGPA2Percentage"
ogDescription="Convert GPA to Percentage using our simple and accurate academic tool."
ogUrl="https://sgpa2percentage.com/gpa-to-percentage-calculator"
ogImage="https://sgpa2percentage.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://sgpa2percentage.com/gpa-to-percentage-calculator",
  "name": "GPA to Percentage Calculator",
  "description": "Convert your GPA to Percentage using our academic tool for precise calculations.",
  "publisher": {
    "@type": "Organization",
    "name": "SGPA2Percentage",
    "logo": {
      "@type": "ImageObject",
      "url": "https://sgpa2percentage.com/img/logo.png"
    }
  }
}}

    >
      <div className="container-fluid">
        <div className='row'>
          <div className='col-md-1 col-sm-0'></div>
          <div className='col-md-8 col-sm-12 main-section'>
            <div className='container text-center'>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">GPA to Percentage Calculator</li>
                </ol>
              </nav>

              <h1 className='text-center'>GPA To Percentage Calculator</h1>
              <p className='text-center'>Convert your GPA to percentage based on different GPA scales and see a visual representation.</p>
              <div className='row'>
                <div className='col-md-12'>
                  <label htmlFor="gpa" className='form-label label'><b>Enter GPA :</b><span className='text-danger'>*</span></label>
                  <input
                    type="number"
                    className="form-control"
                    id='investmentPeriod'
                    value={gpa}
                    onChange={(e) => setGpa(e.target.value)}
                    placeholder="Enter your GPA"
                    required
                    step="0.01"
                  />
                </div>

               
              </div>

              <div className='row input-row'>
              <div className='col-md-12'>
                  <label htmlFor="scale" className='form-label label'><b>Select GPA Scale :</b><span className='text-danger'>*</span></label>
                  <select
                    className="form-control"
                    id='investmentPeriod'
                    value={scale}
                    onChange={(e) => setScale(e.target.value)}
                  >
                    <option value="4.0">4.0 Scale</option>
                    <option value="5.0">5.0 Scale</option>
                    <option value="10.0">10.0 Scale</option>
                  </select>
                </div>
              </div>

              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

              <div className='row input-row'>
                <div className='col-md-12'>
                  <button className="btn btn-primary" onClick={convertToPercentage}>
                    Calculate
                  </button>
                </div>
              </div>
            </div>
            {convertedGpa && (
              <div className='row input-row' style={{ backgroundColor: 'white', height: 'auto', paddingTop: '20px' }}>
                <h3 className='text-center' style={{ color: 'green' }}>
                  Converted Percentage: {convertedGpa.percentage}% 
                  <span> Remaining: {convertedGpa.remaining}%</span>
                </h3>
              </div>
            )}
            {convertedGpa && (
              <div className="container">
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <Doughnut
                      data={{
                        labels: ['Percentage', 'Remaining'],
                        datasets: [
                          {
                            data: [convertedGpa.percentage, convertedGpa.remaining],
                            backgroundColor: ['#36A2EB', '#FFCE56'],
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    {history.length > 0 && (
                      <div className="history-section">
                        <h3 className='text-center'>Conversion History</h3>
                        <div className='table-responsive'>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>GPA</th>
                                <th>Scale</th>
                                <th>Percentage</th>
                                <th>Remaining</th>
                              </tr>
                            </thead>
                            <tbody>
                              {history.map((entry, index) => (
                                <tr key={index}>
                                  <td>{entry.date}</td>
                                  <td>{entry.gpa}</td>
                                  <td>{entry.scale}</td>
                                  <td>{entry.percentage}%</td>
                                  <td>{entry.remaining}%</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <button className="btn btn-danger" onClick={handleClearHistory}>
                            Clear History
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className='container'>
              <div className='row' style={{ marginTop: '50px' }}>
                <hr></hr>
                <h2 className='text-center text-black'>About GPA to Percentage Calculator</h2>
                <hr></hr>

                <p style={{ fontFamily: 'sans-serif' }}>The GPA to Percentage Calculator is a convenient tool that helps convert your Grade Point Average (GPA) into a percentage format. This conversion is especially useful for students who need to present their academic scores in percentage format for applications to universities, job interviews, or other official purposes.</p>
                <h3>Why Convert GPA to Percentage?</h3>
                <div style={{fontFamily:'sans-serif'}}>
                  <p>Many educational institutions and employers prefer percentage scores as they are more familiar with this format. A GPA score might not provide enough context without knowing the grading scale used by the institution. Converting GPA to percentage offers:</p>
                  <ul>
                    <li>Better Clarity: While GPA is an excellent indicator of academic performance, a percentage is easier to understand for individuals used to a percentage grading system.</li>
                    <li>University Applications: Some universities, particularly in countries like India, require students to submit their grades in percentage form.</li>
                    <li>Job Applications: Employers may prefer to see academic scores in a percentage format, which is a common criterion for hiring.</li>
                    <li>Scholarship Eligibility: Certain scholarships are based on percentage-based criteria rather than GPA.</li>
                  </ul>
                </div>
                <h4>Features of the GPA to Percentage Calculator</h4>
                <div style={{fontFamily:'sans-serif'}}>
                  <ul>
                    <li>Supports Multiple GPA Scales: The calculator supports conversion from 4.0, 5.0, and 10.0 GPA scales, making it versatile for students across various educational systems.</li>
                    <li>Accurate Conversion: Provides precise conversion to percentage, ensuring reliability in academic and professional applications.</li>
                    <li>User-Friendly Interface: Simple and intuitive design makes it easy for students, educators, and professionals to use.</li>
                    <li>Instant Results: With a few clicks, the tool gives the percentage equivalent of your GPA, saving time and effort.</li>
                  </ul>
                </div>
                <h4>Benefits of Using the GPA to Percentage Calculator</h4>
                <div style={{fontFamily:'sans-serif'}}>
                  <ul>
                    <li>Quick and Simple: Easily convert GPA into percentage with minimal effort.</li>
                    <li>Accurate and Reliable: Ensures that your conversion is correct, reducing the chances of mistakes in official documents.</li>
                    <li>Time-Saving: Instead of manually calculating the percentage, you get the results instantly.</li>
                    <li>Multi-Scale Support: Supports GPA systems from different countries, making it useful for international students.</li>
                  </ul>
                </div>
                <p style={{fontFamily:"sans-serif"}}>The GPA to Percentage Calculator is a must-have tool for students and professionals who need to present their academic achievements in percentage format. Whether for university applications, job opportunities, or scholarships, this tool provides fast, accurate, and reliable results.</p>
                
              </div>
            </div>
          </div>
          <div className='col-md-3 col-sm-12'>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GPAToPercentageConverter;
